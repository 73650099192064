/**
 * Created by jonathano on 2015-11-04.
 */
var resizeTimer;
var toolBar = (function(){
	var toggle = false;
	var search = $('#search'),
		searchMob = $('#search-mob'),
		toolMob = $('.ic-tool-mob'),
		icon = search.find('i'),
		media = '(max-width: 40em)', 
		up = 'ic-chevron-up',
		down = 'ic-search',
		searchClass = icon.attr('class'),
		shareWidget = $('#share-widget');

	// symbolic constants
	var MAX_TOP = 128;

	function registerChange(mediaQuery) {
		if (!mediaQuery.matches) { // user is on desktop
			searchMob.hide();
			buzzSearch.clearResults();
			buzzSearch.setDescLenght(90);
			buzzSearch.fetchData(null);
			//scrollListener();
			//$(window).on('scroll', scrollListener);
			if (toolMob.hasClass("expanded")) {											
				icon.removeClass(up);
				icon.addClass(down);
				toolMob.removeClass("expanded");	
			}
			if (toggle) { toggle = false; }
			var currentInput = searchMob.find('input');
			if (currentInput.val().length) { currentInput.val(''); }
			
		} else {
			searchMob.hide();
			buzzSearch.clearResults();
			buzzSearch.setDescLenght(50);
			buzzSearch.fetchData(searchMob.find('input'));
		}
	}

	function searchMobAction() {
		search.click(function(e){
			e.preventDefault();
			if ((toggle = !toggle)) {
				//icon.removeClass(searchClass);
				icon.removeClass(down);
				icon.addClass(up);
				toolMob.addClass("expanded");
				buzzSearch.tooglecontentOverlay(true);
			} else {
				icon.addClass(down);
				icon.removeClass(up);
				toolMob.removeClass("expanded");
				searchMob.find('input').val('');
				buzzSearch.clearResults();
			}
			searchMob.slideToggle(30, function() {
				searchMob.find('input').focus();
			});
		});
	}

	function registerHelpers() {
		searchMobAction();
	}

	function onResizeHandler() {
		var main_list_width = $('.main-list').width();
		var sum_width = 0;
		var current_title = null;
		var to_more = false;

		$('.more-button ul.dropdown').html('');


		$('.main-list li').each(function() {
			var el = $(this);
			if(el.hasClass('title')) {
				if(!el.hasClass('hide-on-large')) sum_width += el.width();
				current_title = el;
			} else if(el.hasClass('menu-item')) {
				sum_width += el.width();
				if(to_more || sum_width > main_list_width || el.hasClass('hide-on-large')) {
					to_more = true;
					if(current_title) {
						$('.more-button ul.dropdown').append(current_title.clone().removeClass('hide-on-large'));
						current_title = null;
					}
					$('.more-button ul.dropdown').append(el.clone().removeClass('hide-on-large'));
				}
			}

		});

		if($('.more-button ul.dropdown li').length > 0) {
			$('.more-button').removeClass('hide-on-large');
		} else {
			$('.more-button').addClass('hide-on-large');
		}

		if(!$('#main-nav').hasClass('expanded')) {
			$('body').removeClass('topbar-expanded');
		} else {
			$('body').addClass('topbar-expanded');
		}
	} 

	// publi capi
	return {
		init: function() {
			onResizeHandler();
			$( window ).resize(function() {
				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(onResizeHandler, 100);
			});

			$('.has-dropdown>a').click(function(e) {
				e.preventDefault();
				if(this == document.activeElement) {
					this.blur();
				}
				if($('li.has-dropdown').hasClass('hover')) {
					$('li.has-dropdown').addClass('no-hover');
				} else {
					$('li.has-dropdown').removeClass('no-hover');
				}
			});

			if (matchMedia) {
				var mq  = matchMedia(media);
				mq.addListener(registerChange);
				if (!mq.matches) {
					buzzSearch.fetchData(null);
					buzzSearch.setDescLenght(buzzSearch.MAX_DESC_LENGTH);
				} else {
					searchMob.hide();
					buzzSearch.setDescLenght(buzzSearch.MIN_DESC_LENGTH);
					buzzSearch.fetchData(searchMob.find('input'));
				}
			}
			registerHelpers();
		}
	};
}());
$(window).on('load', toolBar.init);

$(document).ready(function() {
	$('.toggle-topbar>a').click(function(e) {
		e.preventDefault();
		buzzSearch.hideSmartbanner();
		if($('#main-nav').hasClass('expanded')) {
			$('body').removeClass('topbar-expanded');
		} else {
			$('body').addClass('topbar-expanded');
		}
	});
});